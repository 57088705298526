import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_DISPLAY_WEIGHT } from '../queries';
import RemoveSequence from '../mutations/RemoveSequence';
import UpdateSequence from '../mutations/UpdateSequence';

function SequenceInputs(props) {
  const { data } = useQuery(GET_DISPLAY_WEIGHT);
  const { workoutId, exerciseIndex, sequence, sequences } = props;
  return (
    <div className="card__row">
      {data && <UpdateSequence sequence={sequence} displayWeight={data.currentUser.displayWeight} />}
      <div className="input input-set" />
      {sequences > 1 ? (
        <RemoveSequence workoutId={workoutId} exerciseIndex={exerciseIndex} id={sequence.id} />
      ) : (
        <div style={{ width: '48px' }} />
      )}
    </div>
  );
}

export default SequenceInputs;
