import React from 'react';

import stretchingPerson from '../images/stretching.png';
import { Button } from '@rmwc/button';
import '@rmwc/button/styles';
import { Link } from 'react-router-dom';

export const EmptyState = () => {
  return (
    <div className="workouts">
      <div className="stretching-person">
        <img src={stretchingPerson} alt="Person stretching" />
      </div>
      <h1>No Workouts (yet)</h1>
      <h3>Could today be the day?</h3>
      <Button className="cta-button" label="Do the thing" tag={Link} to="/new-workout" unelevated />
    </div>
  );
};
