import React, { useState } from 'react';
import { format } from '../helpers';
import ExerciseBox from './ExerciseBox';
import ExercisesQuery from '../queries/ExercisesQuery';
import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

// const EMPTY_SET = { weight: '', reps: '' };

const WorkoutForm = ({ workout }) => {
  const [showNewExercise, setShowNewExercise] = useState(false);

  const showAddExercise = () => {
    setShowNewExercise(true);
  };

  const hideAddExercise = () => {
    setShowNewExercise(false);
  };

  const renderExercises = () => {
    const exerciseIds = workout.exercises.map(e => e.id);
    return workout.exercises.map((exercise, exerciseIndex) => (
      <ExerciseBox
        exercise={exercise}
        exerciseIndex={exerciseIndex}
        key={exercise.id}
        workoutId={workout.id}
        exerciseIds={exerciseIds}
      />
    ));
  };

  const exercises = renderExercises();
  const exerciseIds = workout.exercises.map(e => e.id);
  return (
    <form autoComplete="off">
      <h2>{format(workout.date)} Workout</h2>
      {!exercises.length && !showNewExercise ? <br /> : exercises}
      {showNewExercise ? (
        <section className="card">
          <ExercisesQuery
            hideAddExercise={hideAddExercise}
            workoutId={workout.id}
            exerciseIds={exerciseIds}
            showNewExercise={showNewExercise}
          />
        </section>
      ) : (
        <Button onClick={showAddExercise} label="add an exercise" icon="add" />
      )}
    </form>
  );
};

export default WorkoutForm;
