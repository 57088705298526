import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from '@apollo/client/react/components';

const UPDATE_SEQUENCE = gql`
  mutation UpdateSequence($id: ID!, $weight: String!, $reps: String!) {
    updateSequence(id: $id, weight: $weight, reps: $reps) {
      id
      weight
      reps
    }
  }
`;

const UpdateSequence = ({ sequence, displayWeight }) => {
  const [state, setState] = useState(sequence);

  const changeSequence = event => {
    const { name, value } = event.target;
    setState({ ...state, [name]: Math.abs(value).toString() });
  };

  const updateSequence = updateMutation => {
    updateMutation({
      variables: state,
      optimisticResponse: {
        __typename: 'Mutation',
        updateSequence: {
          id: state.id,
          __typename: 'Sequence',
          weight: state.weight,
          reps: state.reps
        }
      }
    });
  };

  const { weight, reps } = state;
  return (
    <Mutation mutation={UPDATE_SEQUENCE}>
      {sequenceToUpdate => (
        <>
          <input
            className="card__row-input"
            type="number"
            name="weight"
            value={weight || ''}
            placeholder={displayWeight}
            onChange={changeSequence}
            onBlur={() => {
              updateSequence(sequenceToUpdate);
            }}
          />
          <input
            className="card__row-input"
            type="number"
            name="reps"
            value={reps || ''}
            placeholder="reps"
            onChange={changeSequence}
            onBlur={() => {
              updateSequence(sequenceToUpdate);
            }}
          />
        </>
      )}
    </Mutation>
  );
};

export default UpdateSequence;
