import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Loader from './Loader';

export const GET_USER = gql`
  {
    currentUser {
      email
      firstName
      lastName
      displayWeight
    }
  }
`;

const CHANGE_DISPLAY_WEIGHT = gql`
  mutation CHANGE_DISPLAY_WEIGHT($displayWeight: String!) {
    changeDisplayWeight(displayWeight: $displayWeight) {
      displayWeight
    }
  }
`;

const MyAccount = () => {
  const { loading, error, data } = useQuery(GET_USER);
  const [changeDisplayWeight] = useMutation(CHANGE_DISPLAY_WEIGHT, {
    update(cache, { data: { changeDisplayWeight } }) {
      const { currentUser } = cache.readQuery({ query: GET_USER });
      cache.writeQuery({
        query: GET_USER,
        data: {
          currentUser: {
            ...currentUser,
            displayWeight: changeDisplayWeight.displayWeight
          }
        }
      });
    }
  });

  if (loading) return <Loader />;
  if (error) return <Redirect to="/login" />;
  const { email, firstName, lastName, displayWeight } = data.currentUser;
  return (
    <section>
      <article className="card">
        <h3 className="card__header">Profile</h3>
        <div className="card__input-container">
          <label htmlFor="email">Email</label>
          <input id="email" disabled={true} value={email} />
        </div>
        <div className="card__input-container">
          <label htmlFor="fname">First name</label>
          <input id="fname" disabled={true} value={firstName} />
        </div>
        <div className="card__input-container">
          <label htmlFor="lname">Last name</label>
          <input id="lname" disabled={true} value={lastName} />
        </div>
      </article>

      <article className="card">
        <h3 className="card__header">General</h3>
        <div className="card__input-container--no-label">
          <Link className="card__link" to="/reset-password">
            Change Password
          </Link>
        </div>
        <div className="card__input-container--no-label">
          <Link className="card__link" to="/contact-us">
            Contact / Help
          </Link>
        </div>
      </article>

      <article className="card">
        <h3 className="card__header">App Settings</h3>
        <div className="card__input-container">
          <label htmlFor="measurement">Display weight</label>
          <select
            id="measurement"
            value={displayWeight}
            onChange={e => {
              changeDisplayWeight({
                variables: { displayWeight: e.target.value }
              });
            }}
          >
            <option value="kilograms">kilograms</option>
            <option value="pounds">pounds</option>
          </select>
        </div>
      </article>
    </section>
  );
};

export default MyAccount;
