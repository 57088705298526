import React, { useState } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import ReactGA from 'react-ga';
import PrivateRoute from './PrivateRoute';
import MyAccount from './MyAccount';
import ContactUs from './ContactUs';
import WorkoutsQuery from '../queries/WorkoutsQuery';
import WorkoutQuery from '../queries/WorkoutQuery';
import CreateWorkout from '../mutations/CreateWorkout';
import LoginForm from './LoginForm';
import ResetPassword from './ResetPassword';
import ConfirmEmail from './ConfirmEmail';
import TopAppBar from './TopAppBar';
import Navigation from './Navigation';
import ExerciseStatsQuery from '../queries/ExerciseStats';
import NotFound from './NotFound';
import SnackBar from './SnackBar';
import { authToken, setAuthToken, removeAuthToken, inProduction } from '../helpers';

if (inProduction()) {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
  ReactGA.pageview('/homepage');
}

const App = ({ location, history, client }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!authToken());
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const logout = () => {
    removeAuthToken();
    setIsLoggedIn(false);
    client.clearStore();
  };

  const login = token => {
    setAuthToken(token);
    setIsLoggedIn(true);
  };

  const displaySnackBar = message => {
    setShowSnackBar(true);
    setSnackBarMessage(message);
  };

  const hideSnackbar = () => {
    setShowSnackBar(false);
    setSnackBarMessage('');
  };

  return (
    <div>
      <TopAppBar location={location} history={history} isLoggedIn={isLoggedIn} logout={logout} />
      {showSnackBar && <SnackBar hideSnackbar={hideSnackbar} message={snackBarMessage} />}
      <div className="container">
        <Switch>
          <Route path="/login" render={props => <LoginForm {...props} login={login} />} />
          <Route path="/signup" render={props => <LoginForm {...props} login={login} signup />} />
          <Route
            path="/reset-password"
            render={props => <ResetPassword {...props} login={login} displaySnackBar={displaySnackBar} />}
          />
          <Route
            path="/confirm-email"
            render={props => <ConfirmEmail {...props} login={login} displaySnackBar={displaySnackBar} />}
          />
          <PrivateRoute isLoggedIn={isLoggedIn} exact path="/" component={WorkoutsQuery} />
          <PrivateRoute isLoggedIn={isLoggedIn} path="/my-account" component={MyAccount} />
          <PrivateRoute isLoggedIn={isLoggedIn} path="/contact-us" component={ContactUs} />
          <Route
            isLoggedIn={isLoggedIn}
            path="/workouts/:id"
            render={props => {
              if (!isLoggedIn) return <Redirect to="/login" />;
              return <WorkoutQuery {...props} />;
            }}
          />
          <PrivateRoute isLoggedIn={isLoggedIn} path="/stats" component={ExerciseStatsQuery} />
          <Route
            path="/new-workout"
            render={() => {
              if (!isLoggedIn) return <Redirect to="/login" />;
              return <CreateWorkout />;
            }}
          />
          <Route path="/404" component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </div>
      <Navigation isLoggedIn={isLoggedIn} />
    </div>
  );
};

export default withApollo(withRouter(App));
