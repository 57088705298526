// env helpers
export const inProduction = () => process.env.NODE_ENV === 'production';

// auth helpers
export const setAuthToken = authToken => localStorage.setItem('auth_token', authToken);

export const removeAuthToken = () => localStorage.removeItem('auth_token');

export const authToken = () => localStorage.getItem('auth_token');

// view helpers
export const format = date => {
  date = new Date(date);
  var options = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

export const shorten = displayWeight => {
  if (displayWeight === 'pounds') {
    return 'lbs';
  } else {
    return 'kgs';
  }
};
