import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import Select from 'react-select';
import { GET_WORKOUT } from '../queries';

const UPDATE_EXERCISE = gql`
  mutation UpdateExercise($oldId: ID!, $newId: ID!, $workoutId: ID!) {
    updateExercise(oldId: $oldId, newId: $newId, workoutId: $workoutId) {
      id
      name
    }
  }
`;

export default function UpdateExercise(props) {
  const { workoutId, exerciseIndex, suggestionValues, components, exercise, handleChange } = props;
  return (
    <Mutation
      mutation={UPDATE_EXERCISE}
      update={(cache, { data: { updateExercise } }) => {
        const { workout } = cache.readQuery({
          query: GET_WORKOUT,
          variables: { id: workoutId }
        });
        const { sequences } = workout.exercises[exerciseIndex];
        cache.writeQuery({
          query: GET_WORKOUT,
          variables: { id: workoutId },
          data: {
            workout: {
              ...workout,
              exercises: workout.exercises.map((exercise, index) => {
                if (exerciseIndex === index) {
                  const newExercise = { ...updateExercise };
                  newExercise.sequences = sequences;
                  return newExercise;
                }
                return exercise;
              })
            }
          }
        });
      }}
    >
      {updateExercise => (
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          options={suggestionValues}
          components={components}
          value={exercise}
          onChange={inputValue => {
            handleChange(inputValue, updateExercise);
          }}
          placeholder="Exercise"
          isClearable
        />
      )}
    </Mutation>
  );
}
