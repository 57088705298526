import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import CreatableSelect from 'react-select/creatable';
import { GET_WORKOUT } from '../queries';

const ADD_EXERCISE = gql`
  mutation AddExercise($exerciseName: String!, $workoutId: ID!) {
    addExercise(exerciseName: $exerciseName, workoutId: $workoutId) {
      id
      weight
      reps
      exercise {
        id
        name
      }
    }
  }
`;

export default function AddExercise(props) {
  const { workoutId, suggestionValues, components, exercise, handleChange } = props;
  const [addExerciseMutation] = useMutation(ADD_EXERCISE, {
    update(cache, { data: { addExercise } }) {
      const { workout } = cache.readQuery({
        query: GET_WORKOUT,
        variables: { id: workoutId }
      });
      const exercise = { ...addExercise.exercise };
      exercise.sequences = [addExercise];
      cache.writeQuery({
        query: GET_WORKOUT,
        variables: { id: workoutId },
        data: {
          workout: {
            ...workout,
            exercises: [...workout.exercises, exercise]
          }
        }
      });
    },
    refetchQueries: ['GetExercises']
  });
  return (
    <CreatableSelect
      className="react-select-container"
      classNamePrefix="react-select"
      options={suggestionValues}
      components={components}
      value={exercise}
      onChange={inputValue => {
        handleChange(inputValue, addExerciseMutation);
      }}
      placeholder="Exercise"
      onCreateOption={inputValue => {
        handleChange(inputValue, addExerciseMutation);
      }}
      isClearable
    />
  );
}
