import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import Text from '../components/Text';
import ExerciseAutocomplete from '../components/ExerciseAutocomplete';
import { GET_EXERCISES } from './index';

export default function ExercisesQuery(props) {
  const { loading, error, data } = useQuery(GET_EXERCISES);

  if (loading) return <div />;
  if (error) return <Text text={error} />;
  return <Fragment>{data.exercises && <ExerciseAutocomplete {...props} suggestions={data.exercises} />}</Fragment>;
}
