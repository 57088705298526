import React, { useState } from 'react';
import ExerciseQuery from '../queries/ExerciseQuery';
import { EmptyState } from './EmptyState';

const Stats = ({ exercises, searchParams }) => {
  let exerciseId;
  if (searchParams) exerciseId = searchParams.split('=')[1];
  const defaultId = exercises.length > 0 ? exercises[0].id : null;
  const [currentExerciseId, setCurrentExerciseId] = useState(exerciseId || defaultId);

  const handleChange = e => {
    const exercise = exercises.find(exercise => exercise.name === e.target.value);
    setCurrentExerciseId(exercise.id);
  };

  if (exercises.length === 0) return <EmptyState />;
  const exerciseNames = exercises.map(exercise => (
    <option key={exercise.id} value={exercise.name}>
      {exercise.name}
    </option>
  ));
  const exercise = exercises.find(exercise => exercise.id === currentExerciseId);
  return (
    <>
      <div>
        <h2>Exercise History</h2>
        <select className="exercise-select" value={exercise.name} onChange={handleChange}>
          {exerciseNames}
        </select>
      </div>
      <ExerciseQuery id={exercise.id} />
    </>
  );
};

export default Stats;
