import React from 'react';
import { Link } from 'react-router-dom';

function HelperLinks({ signup }) {
  let links;
  if (signup) {
    links = (
      <div className="light">
        <span>Already have an account? </span>
        <Link to="/login" className="link-small">
          Sign in
        </Link>
        <br />
        <span>By signing up, you agree to our </span>
        <a
          href="https://app.termly.io/document/privacy-policy/945e9283-f04e-451f-a746-49bffa3eacc4"
          target="_blank"
          rel="noopener noreferrer"
          className="link-small"
        >
          privacy policy
        </a>
      </div>
    );
  } else {
    links = (
      <div className="light">
        <Link to="/reset-password" variant="body2" className="link-small">
          Forgot Password?
        </Link>
        <br />
        <br />
        <span>Need an account? </span>
        <Link to="/signup" className="link-small">
          Sign up
        </Link>
      </div>
    );
  }
  return links;
}

export default HelperLinks;
