import React from 'react';
import { Link } from 'react-router-dom';
import { TabBar, Tab } from '@rmwc/tabs';
import '@rmwc/tabs/styles';

function Navigation({ isLoggedIn }) {
  const [activeTab, setActiveTab] = React.useState(0);

  if (!isLoggedIn) return null;

  return (
    <div className="bottom-nav">
      <TabBar
        className="bottom-nav-content"
        activeTabIndex={activeTab}
        onActivate={evt => setActiveTab(evt.detail.index)}
      >
        <Tab tag={Link} to="/" stacked icon="home" label="Home" />
        <Tab tag={Link} to="/new-workout" stacked icon="fitness_center" label="New Workout" />
        <Tab tag={Link} to="/stats" stacked icon="trending_up" label="Stats" />
      </TabBar>
    </div>
  );
}

export default Navigation;
