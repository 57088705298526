import React from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Loader from '../components/Loader';
import ExerciseStats from '../components/ExerciseStats';

export const GET_EXERCISE_STATS = gql`
  {
    exerciseStats {
      id
      name
    }
  }
`;

export default function ExerciseStatsQuery(props) {
  const { loading, error, data } = useQuery(GET_EXERCISE_STATS, {
    fetchPolicy: 'network-only'
  });

  if (loading) return <Loader />;
  if (error) return <Redirect to="/404" />;

  return (
    <div>
      {data.exerciseStats && <ExerciseStats exercises={data.exerciseStats} searchParams={props.location.search} />}
    </div>
  );
}
