import React, { useEffect } from 'react';
import { confirmEmail } from '../api';

const ConfirmEmail = ({ displaySnackBar, history, login }) => {
  useEffect(() => {
    async function fetchData() {
      const token = history.location.search.split('=')[1];
      if (token) {
        const data = await confirmEmail(token, true);
        login(data.auth_token);
        history.push('/');
        displaySnackBar('Thanks for confirming your email!');
      }
    }
    fetchData();
  }, [history, login, displaySnackBar]);

  const sendEmailVerification = async () => {
    await confirmEmail(localStorage.getItem('email'));
    displaySnackBar('Please check your email for a link to confirm your email');
  };

  return (
    <main className="light">
      <p>Finish your registration by clicking the link in the confirmation email we just sent.</p>
      <p>
        <span>Not seeing it? </span>
        <button className="btn-link link-small" onClick={sendEmailVerification}>
          Resend Email
        </button>
      </p>
    </main>
  );
};

export default ConfirmEmail;
