import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';
import Loader from '../components/Loader';
import Workouts from '../components/Workouts';

export const GET_WORKOUTS = gql`
  query GetWorkouts {
    workouts {
      id
      date
      exercises {
        id
        name
      }
    }
  }
`;

const ARCHIVE_WORKOUT = gql`
  mutation ArchiveWorkout($id: ID!) {
    archiveWorkout(id: $id) {
      id
    }
  }
`;

const COPY_WORKOUT = gql`
  mutation CopyWorkout($id: ID!) {
    copyWorkout(id: $id) {
      id
      date
      exercises {
        id
        name
      }
    }
  }
`;

export default function WorkoutsQuery() {
  const { loading, error, data } = useQuery(GET_WORKOUTS, {
    fetchPolicy: 'network-only'
  });
  const [archiveWorkout] = useMutation(ARCHIVE_WORKOUT, {
    update(cache, { data: { archiveWorkout } }) {
      const { workouts } = cache.readQuery({ query: GET_WORKOUTS });
      cache.writeQuery({
        query: GET_WORKOUTS,
        data: {
          workouts: workouts.filter(workout => workout.id !== archiveWorkout.id)
        }
      });
    }
  });

  const [copyWorkout] = useMutation(COPY_WORKOUT, {
    update(cache, { data: { copyWorkout } }) {
      const { workouts } = cache.readQuery({ query: GET_WORKOUTS });
      cache.writeQuery({
        query: GET_WORKOUTS,
        data: {
          workouts: [copyWorkout, ...workouts]
        }
      });
    }
  });

  if (loading) return <Loader />;
  if (error) return <Redirect to="/login" />;
  return (
    <div>
      {data.workouts && (
        <Workouts archiveWorkoutMutation={archiveWorkout} copyWorkoutMutation={copyWorkout} workouts={data.workouts} />
      )}
    </div>
  );
}
