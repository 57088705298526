import { authToken, removeAuthToken } from '../helpers';
import { history } from '../history';

export const checkResponse = response => {
  if (response.status === 401) {
    removeAuthToken();
    history.replace('/login');
  }
  if (!response.ok) throw new Error(`${response.status} returned from server.`);
};

export const request = async (url, options = {}) => {
  if (!options.headers) options.headers = {};
  options.headers.Authorization = authToken();
  const response = await fetch(url, options);
  checkResponse(response);
  return response;
};

export const authenticateUser = async (user, signup = false) => {
  const endpoint = signup ? 'signup' : 'login';
  const response = await request(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
    method: 'POST',
    body: JSON.stringify(user),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response.json();
};

export const confirmEmail = async (data, hasToken = false) => {
  let params;
  if (hasToken) {
    params = { token: data };
  } else {
    params = { email: data };
  }

  const response = await request(`${process.env.REACT_APP_API_URL}/verify`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response.json();
};

export const sendPasswordLink = async email => {
  const response = await request(`${process.env.REACT_APP_API_URL}/password/forgot`, {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response.json();
};

export const resetPassword = async (email, password, token) => {
  const response = await request(`${process.env.REACT_APP_API_URL}/password/reset`, {
    method: 'POST',
    body: JSON.stringify({ email, password, token }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response.json();
};
