import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { GET_WORKOUT } from '../queries';
import { IconButton } from '@rmwc/icon-button';
import '@rmwc/icon-button/styles';

const REMOVE_EXERCISE = gql`
  mutation RemoveExercise($exerciseId: ID!, $workoutId: ID!) {
    removeExercise(exerciseId: $exerciseId, workoutId: $workoutId) {
      id
    }
  }
`;

export default function RemoveExercise(props) {
  const { workoutId, exercise } = props;
  const [removeExercise] = useMutation(REMOVE_EXERCISE, {
    update(cache, { data: { removeExercise } }) {
      const { workout } = cache.readQuery({
        query: GET_WORKOUT,
        variables: { id: workoutId }
      });
      const { exercises } = workout;
      cache.writeQuery({
        query: GET_WORKOUT,
        variables: { id: workoutId },
        data: {
          workout: {
            ...workout,
            exercises: exercises.filter(exercise => exercise.id !== removeExercise.id)
          }
        }
      });
    }
  });
  return (
    <IconButton
      aria-label="Remove Exercise"
      icon="delete_outline"
      onClick={() => {
        removeExercise({
          variables: { exerciseId: exercise.id, workoutId },
          optimisticResponse: {
            __typename: 'Mutation',
            removeExercise: {
              __typename: 'Exercise',
              id: exercise.id
            }
          }
        });
      }}
    />
  );
}
