import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { resetPassword, sendPasswordLink } from '../api';
import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

const ResetPassword = ({ login, history, displaySnackBar }) => {
  const [message, setMessage] = useState('');
  const handleSubmit = async (event, token = null) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const { email, password } = Object.fromEntries(formData.entries());
    try {
      if (token) {
        const data = await resetPassword(email, password, token);
        login(data.auth_token);
        history.push('/');
        displaySnackBar('Your password has been reset!');
      } else {
        await sendPasswordLink(email);
        setMessage('Please check your email for a link to reset your password');
      }
    } catch (error) {
      setMessage('Something went wrong. Please try again');
      history.push({
        pathname: '/reset-password',
        search: ''
      });
    }
  };

  let token;
  if (window.location.search) {
    token = window.location.search.split('=')[1];
  }

  return (
    <main>
      <article className="card">
        <h3 className="card__header">Reset Your Password</h3>
        <form onSubmit={e => handleSubmit(e, token)}>
          {token ? (
            <div className="card__input-container">
              <label htmlFor="password">Password</label>
              <input name="password" type="password" id="password" autoComplete="current-password" />
            </div>
          ) : (
            <div className="card__input-container">
              <label htmlFor="email">Email Address</label>
              <input id="email" name="email" autoComplete="email" autoFocus />
            </div>
          )}
          <Link to="/login" className="link-small">
            Back to Log in
          </Link>
          <p>{message}</p>
          <Button unelevated type="submit" className="cta-button btn-wide">
            {token ? 'Reset Password' : 'Send Reset Link'}
          </Button>
        </form>
      </article>
    </main>
  );
};

export default ResetPassword;
