import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Loader from '../components/Loader';
import WorkoutForm from '../components/WorkoutForm';
import { GET_WORKOUT } from './index';

export default function WorkoutQuery({ match }) {
  const { id } = match.params;
  const { loading, error, data } = useQuery(GET_WORKOUT, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  if (loading) return <Loader />;
  if (error) return <Redirect to="/404" />;

  return <div>{data.workout && <WorkoutForm workout={data.workout} />}</div>;
}

WorkoutQuery.propTypes = {
  match: PropTypes.shape({
    id: PropTypes.string
  }).isRequired
};
