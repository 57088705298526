import React from 'react';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import '@rmwc/snackbar/styles';

export default function SnackBar(props) {
  const { message, hideSnackbar } = props;
  const [open, setOpen] = React.useState(true);

  return (
    <Snackbar
      open={open}
      onClose={evt => setOpen(false)}
      message={message}
      dismissesOnAction
      action={<SnackbarAction label="hide" onClick={hideSnackbar} />}
    />
  );
}
