import gql from 'graphql-tag';

export const GET_WORKOUT = gql`
  query GetWorkout($id: ID!) {
    workout(id: $id) {
      id
      date
      exercises {
        id
        name
        sequences {
          id
          weight
          reps
        }
      }
    }
  }
`;

export const GET_DISPLAY_WEIGHT = gql`
  query GetDisplayWeight {
    currentUser {
      displayWeight
    }
  }
`;

export const GET_EXERCISES = gql`
  query GetExercises {
    exercises {
      id
      name
    }
  }
`;
