import React from 'react';
import { Link } from 'react-router-dom';
import ExercisesQuery from '../queries/ExercisesQuery';
import SequenceInputs from './SequenceInputs';
import AddSequence from '../mutations/AddSequence';
import RemoveExercise from '../mutations/RemoveExercise';
import { IconButton } from '@rmwc/icon-button';
import '@rmwc/icon-button/styles';

const ExerciseBox = ({ exercise, workoutId, exerciseIds, exerciseIndex }) => {
  const renderSequences = exerciseIndex => {
    return exercise.sequences.map(sequence => (
      <SequenceInputs
        key={sequence.id}
        sequence={sequence}
        sequences={exercise.sequences.length}
        exerciseIndex={exerciseIndex}
        workoutId={workoutId}
      />
    ));
  };

  const sequences = renderSequences(exerciseIndex);
  const lastSequence = exercise.sequences[exercise.sequences.length - 1];
  return (
    <section key={exerciseIndex} className="card">
      <ExercisesQuery
        exercise={exercise}
        exerciseIndex={exerciseIndex}
        workoutId={workoutId}
        exerciseIds={exerciseIds}
      />
      <div className="card__row card__row--header">
        <h3>Weight</h3>
        <h3>Reps</h3>
        <div style={{ width: '115px' }} />
      </div>

      {sequences}
      {lastSequence.reps !== '' && (
        <AddSequence exercise={exercise} exerciseIndex={exerciseIndex} workoutId={workoutId} />
      )}
      <div className="card__row card__row--last">
        <IconButton
          aria-label="View Exercise Stats"
          icon="trending_up"
          tag={Link}
          to={`/stats?exerciseId=${exercise.id}`}
        />
        <RemoveExercise exercise={exercise} workoutId={workoutId} />
      </div>
    </section>
  );
};

export default ExerciseBox;
