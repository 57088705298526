import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu';
import '@rmwc/menu/styles';
import { IconButton } from '@rmwc/icon-button';
import '@rmwc/icon-button/styles';

function MenuAppBar({ logout, isLoggedIn }) {
  const [open, setOpen] = React.useState(false);

  return (
    <nav className="top-nav">
      <div className="top-nav-content">
        <img src={logo} alt="Logo" className="top-nav__logo" />
        {isLoggedIn && (
          <div>
            <MenuSurfaceAnchor>
              <Menu open={open} onSelect={e => console.log(e.detail.index)} onClose={e => setOpen(false)}>
                <MenuItem tag={Link} to="/my-account">
                  Account
                </MenuItem>
                <MenuItem tag={Link} to="/contact-us">
                  Contact us
                </MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
                <MenuItem>
                  <a
                    target="_blank"
                    href="https://app.termly.io/document/privacy-policy/945e9283-f04e-451f-a746-49bffa3eacc4"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </MenuItem>
              </Menu>
              <IconButton
                className="material-icons md-dark"
                onClick={e => setOpen(!open)}
                icon="face"
                label="Open Menu"
              />
            </MenuSurfaceAnchor>
          </div>
        )}
      </div>
    </nav>
  );
}

export default MenuAppBar;
