import React, { useEffect, useState, useCallback } from 'react';
import uniq from 'lodash.uniq';
import WorkoutCard from './WorkoutCard';
import { EmptyState } from './EmptyState';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const Workouts = ({ workouts, copyWorkoutMutation, archiveWorkoutMutation }) => {
  const [monthSelect, setMonthSelect] = useState('All Time');
  const [workoutsState, setWorkoutsState] = useState(workouts);

  const copyWorkout = async id => {
    const response = await copyWorkoutMutation({ variables: { id } });
    setWorkoutsState([response.data.copyWorkout, ...workouts]);
  };

  const archiveWorkout = useCallback(
    id => {
      archiveWorkoutMutation({ variables: { id } });
      setWorkoutsState(workoutsState.filter(workout => workout.id !== id));
    },
    [archiveWorkoutMutation, workoutsState]
  );

  const filterByMonth = ({ target }) => {
    setMonthSelect(target.value);
  };

  const renderMonthsToSelect = workouts => {
    const workoutMonths = workouts.map(workout => {
      const workoutDate = new Date(workout.date);
      return `${months[workoutDate.getMonth()]} ${workoutDate.getFullYear()}`;
    });
    workoutMonths.unshift('All Time');
    return uniq(workoutMonths).map(month => (
      <option key={month} value={month}>
        {month}
      </option>
    ));
  };

  const renderWorkoutCards = () => {
    let result = workoutsState;
    if (monthSelect !== 'All Time') {
      result = workoutsState.filter(workout => {
        const workoutDate = new Date(workout.date);
        const workoutMonth = `${months[workoutDate.getMonth()]} ${workoutDate.getFullYear()}`;
        return workoutMonth === monthSelect;
      });
    }
    return result.map(workout => (
      <WorkoutCard key={workout.id} workout={workout} archiveWorkout={archiveWorkout} copyWorkout={copyWorkout} />
    ));
  };

  useEffect(() => {
    workoutsState.forEach(workout => {
      if (workout.exercises.length === 0) archiveWorkout(workout.id);
    });
  }, [workoutsState, archiveWorkout]);

  if (workoutsState.length === 0) return <EmptyState />;
  const monthsToSelect = renderMonthsToSelect(workoutsState);
  const workoutCards = renderWorkoutCards();
  const timeline = monthSelect === 'All Time' ? 'on weighto' : `in ${monthSelect}`;
  return (
    <div>
      <select className="month-select" value={monthSelect} onChange={filterByMonth}>
        {monthsToSelect}
      </select>
      <p>{`Way to go! You've completed ${workoutCards.length} workouts ${timeline}.`}</p>
      {workoutCards}
    </div>
  );
};

export default Workouts;
