import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { GET_WORKOUT } from '../queries';
import { IconButton } from '@rmwc/icon-button';
import '@rmwc/icon-button/styles';

const REMOVE_SEQUENCE = gql`
  mutation RemoveSequence($id: ID!) {
    removeSequence(id: $id) {
      id
    }
  }
`;

export default function RemoveSequence(props) {
  const { workoutId, exerciseIndex, id } = props;
  const [removeSequence] = useMutation(REMOVE_SEQUENCE, {
    update(cache, { data: { removeSequence } }) {
      const { workout } = cache.readQuery({
        query: GET_WORKOUT,
        variables: { id: workoutId }
      });
      const { sequences } = workout.exercises[exerciseIndex];
      cache.writeQuery({
        query: GET_WORKOUT,
        variables: { id: workoutId },
        data: {
          workout: {
            ...workout,
            exercises: workout.exercises.map((exercise, index) => {
              if (exerciseIndex === index) {
                const updatedExercise = { ...exercise };
                updatedExercise.sequences = sequences.filter(sequence => sequence.id !== removeSequence.id);
                return updatedExercise;
              }
              return exercise;
            })
          }
        }
      });
    }
  });
  return (
    <IconButton
      icon="clear"
      aria-label="Remove Sequence"
      onClick={() => {
        removeSequence({
          variables: { id },
          optimisticResponse: {
            __typename: 'Mutation',
            removeSequence: {
              __typename: 'Sequence',
              id
            }
          }
        });
      }}
    >
      remove set
    </IconButton>
  );
}
