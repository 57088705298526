import React from 'react';

const ContactUs = () => (
  <div>
    <h6>We're here if you need us. Please send any questions or concerns to</h6>
    <a href="mailto:weighto.app@gmail.com">weighto.app@gmail.com</a>
  </div>
);

export default ContactUs;
