import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, ApolloProvider, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { history } from './history';
import { authToken } from './helpers';

// fonts and css
import 'typeface-poppins';
import './index.css';

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      authorization: authToken() || '', // however you get your token
      ...headers
    }
  }));
  return forward(operation);
});

const link = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(link)]),
  cache: new InMemoryCache({
    freezeResults: true
  }),
  assumeImmutableResults: true
});

const app = (
  <ApolloProvider client={client}>
    <Router history={history}>
      <React.Fragment>
        <App />
      </React.Fragment>
    </Router>
  </ApolloProvider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
