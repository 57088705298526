import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';

export const GET_WORKOUTS = gql`
  query GetWorkouts {
    workouts {
      id
      date
      exercises {
        id
        name
      }
    }
  }
`;

const CREATE_WORKOUT = gql`
  mutation CreateWorkout($date: String!) {
    createWorkout(date: $date) {
      id
      date
    }
  }
`;

const CallCreateWorkout = ({ createWorkout, children }) => {
  useEffect(() => {
    createWorkout({ variables: { date: new Date().toString() } });
  }, [createWorkout]);

  return children;
};

export default function CreateWorkout() {
  const [createWorkout, { data }] = useMutation(CREATE_WORKOUT, {
    update(cache, { data: { createWorkout } }) {
      const { workouts } = cache.readQuery({ query: GET_WORKOUTS });
      cache.writeQuery({
        query: GET_WORKOUTS,
        data: {
          workouts: [createWorkout, ...workouts]
        }
      });
    }
  });

  return (
    <CallCreateWorkout createWorkout={createWorkout}>
      <div>{data && <Redirect to={`/workouts/${data.createWorkout.id}`} />}</div>
    </CallCreateWorkout>
  );
}
