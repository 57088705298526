import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { GET_WORKOUT } from '../queries';
import uuidv1 from 'uuid/v1';
import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

const ADD_SEQUENCE = gql`
  mutation AddSequence($id: ID!, $exerciseId: ID!, $workoutId: ID!) {
    addSequence(id: $id, exerciseId: $exerciseId, workoutId: $workoutId) {
      id
      weight
      reps
    }
  }
`;

export default function AddSequence(props) {
  const { exercise, exerciseIndex, workoutId } = props;
  const [addSequence] = useMutation(ADD_SEQUENCE, {
    update(cache, { data: { addSequence } }) {
      const { workout } = cache.readQuery({
        query: GET_WORKOUT,
        variables: { id: workoutId }
      });
      const { sequences } = workout.exercises[exerciseIndex];
      cache.writeQuery({
        query: GET_WORKOUT,
        variables: { id: workoutId },
        data: {
          workout: {
            ...workout,
            exercises: workout.exercises.map((exercise, index) => {
              if (exerciseIndex === index) {
                const updatedExercise = { ...exercise };
                updatedExercise.sequences = [...sequences, addSequence];
                return updatedExercise;
              }
              return exercise;
            })
          }
        }
      });
    }
  });
  const id = uuidv1();
  return (
    <Button
      icon="add"
      label="add a set"
      onClick={() => {
        addSequence({
          variables: { id, exerciseId: exercise.id, workoutId },
          optimisticResponse: {
            __typename: 'Mutation',
            addSequence: {
              __typename: 'Sequence',
              id,
              reps: '',
              weight: ''
            }
          }
        });
      }}
    />
  );
}
