import React from 'react';
import { Link } from 'react-router-dom';
import { format, shorten } from '../helpers';
import { useQuery } from '@apollo/client';
import { GET_DISPLAY_WEIGHT } from '../queries';

const HistoryCard = props => {
  const { data } = useQuery(GET_DISPLAY_WEIGHT);
  const { workout } = props;
  const sequences = workout.sequences.map(sequence => {
    const { id, weight, reps, bodyweight } = sequence;
    return (
      <div key={id} className="card__row card__row--stats">
        {data && !bodyweight ? (
          <h3 className="light">
            {weight} {weight && shorten(data.currentUser.displayWeight)}
          </h3>
        ) : (
          <div />
        )}
        <h3 className="light">{reps}</h3>
      </div>
    );
  });

  const isBodyWeight = workout.sequences.every(sequence => sequence.bodyweight);
  return (
    <div className="card">
      <div className="card__header-container card__header--stats">
        <Link className="card__link--header" to={`/workouts/${workout.id}`}>
          {format(workout.date)}
        </Link>
      </div>
      <div>
        <div className="card__row card__row--header card__row--stats">
          {!isBodyWeight && <h3>Weight</h3>}
          <h3>Reps</h3>
        </div>
        {sequences}
      </div>
    </div>
  );
};

export default HistoryCard;
