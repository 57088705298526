import React, { Fragment } from 'react';

function SignupFields() {
  return (
    <Fragment>
      <div className="card__input-container">
        <label htmlFor="text">First Name</label>
        <input id="firstName" name="firstName" autoComplete="given-name" autoFocus />
      </div>
      <div className="card__input-container">
        <label htmlFor="text">Last Name</label>
        <input id="lastName" name="lastName" autoComplete="family-name" autoFocus />
      </div>
    </Fragment>
  );
}

export default SignupFields;
