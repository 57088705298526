import React, { useState } from 'react';
import { authenticateUser } from '../api';
import '@rmwc/button/styles';
import SignupFields from './SignupFields';
import HelperLinks from './HelperLinks';
import { Button } from './Button';

const LoginForm = ({ signup, login, history }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleError = error => {
    let message;
    if (signup && error.message.includes('422')) {
      message = 'An account with this email address already exists. Please login or use another email address';
    } else if (error.message.includes('404')) {
      message = 'Account not found. Please sign up instead.';
    } else if (error.message.includes('Failed to fetch')) {
      message = 'Internet connection not found. Please connect before using this app.';
    } else {
      message = 'Incorrect Email/Password combination';
    }
    setErrorMessage(message);
  };

  const handleSubmit = async event => {
    setIsLoading(true);
    event.preventDefault();
    const formData = new FormData(event.target);
    const { firstName, lastName, email, password } = Object.fromEntries(formData.entries());

    try {
      if (signup && (!firstName || !lastName)) {
        setErrorMessage('Please fill out all fields');
        return;
      }
      if (password.length < 8) {
        setErrorMessage('Your password must be at least 8 characters. Please try again.');
        return;
      }
      const data = await authenticateUser(
        {
          firstName,
          lastName,
          email,
          password
        },
        signup
      );
      setIsLoading(false);
      if (data.email) {
        localStorage.setItem('email', data.email);
        history.push('/confirm-email');
      } else {
        localStorage.removeItem('email');
        login(data.auth_token);
        history.push('/');
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <main>
      <article className="card">
        <h3 className="card__header">{signup ? 'Welcome to Weighto!' : 'Welcome back to Weighto!'}</h3>
        <form onSubmit={handleSubmit}>
          {signup && <SignupFields />}
          <div className="card__input-container">
            <label htmlFor="email">Email Address</label>
            <input id="email" name="email" autoComplete="email" autoFocus />
          </div>
          <div className="card__input-container">
            <label htmlFor="password">Password</label>
            <input name="password" type="password" id="password" autoComplete="current-password" />
          </div>
          <p>{errorMessage}</p>
          <Button
            className="cta-button btn-wide"
            label={signup ? 'Sign up' : 'Sign in'}
            type="submit"
            unelevated
            isLoading={isLoading}
          />
          <HelperLinks signup={signup} />
        </form>
      </article>
    </main>
  );
};

LoginForm.defaultProps = {
  signup: false
};

export default LoginForm;
