import React from 'react';
import { Link } from 'react-router-dom';
import { format } from '../helpers';
import { IconButton } from '@rmwc/icon-button';
import { Icon } from '@rmwc/icon';
import '@rmwc/icon-button/styles';

const WorkoutCard = props => {
  const { workout, archiveWorkout, copyWorkout } = props;
  const exercises = workout.exercises.map(exercise => (
    <h3 className="light" key={exercise.id}>
      {exercise.name}
    </h3>
  ));

  return (
    <article className="card">
      <div className="card__header-container">
        <Link className="card__link--header" to={`/workouts/${workout.id}`}>
          {format(workout.date)}
          <Icon icon={{ icon: 'create', size: 'small' }} style={{ marginLeft: '5px' }} />
        </Link>
        <IconButton onClick={() => archiveWorkout(workout.id)} icon="delete_outline" label="Remove Workout" />
      </div>
      {exercises}
      <IconButton onClick={() => copyWorkout(workout.id)} icon="content_copy" label="Copy Workout" />
    </article>
  );
};

export default WorkoutCard;
