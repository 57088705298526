import React, { useState } from 'react';
import UpdateExercise from '../mutations/UpdateExercise';
import AddExercise from '../mutations/AddExercise';
import uuidv1 from 'uuid/v1';

const ExerciseAutocomplete = ({
  exercise,
  workoutId,
  hideAddExercise,
  suggestions,
  exerciseIndex,
  exerciseIds,
  showNewExercise
}) => {
  const [newExercise, setNewExercise] = useState(
    exercise
      ? {
          id: exercise.id,
          value: exercise.name,
          label: exercise.name
        }
      : null
  );
  const oldId = exercise?.id;

  const handleAddExercise = (inputValue, addExercise) => {
    setNewExercise(inputValue);
    if (!inputValue) return;
    let name;
    let id;
    if (typeof inputValue === 'string') {
      name = inputValue;
      id = uuidv1();
    } else {
      name = inputValue.value;
      id = inputValue.id;
    }
    addExercise({
      variables: {
        exerciseName: name,
        workoutId
      },
      optimisticResponse: {
        __typename: 'Mutation',
        addExercise: {
          __typename: 'Sequence',
          id: uuidv1(),
          reps: '',
          weight: '',
          exercise: {
            __typename: 'Exercise',
            id,
            name
          }
        }
      }
    });
    hideAddExercise();
  };

  const handleUpdateExercise = (inputValue, updateExercise) => {
    setNewExercise(inputValue);
    if (!inputValue) return;
    updateExercise({
      variables: {
        oldId,
        newId: inputValue.id,
        workoutId
      }
    });
  };

  let id = null;
  if (newExercise) id = newExercise.id;
  const suggestionValues = suggestions.map(suggestion => ({
    id: suggestion.id,
    value: suggestion.name,
    label: suggestion.name,
    className: 'suggestion',
    isDisabled: exerciseIds.includes(suggestion.id) && suggestion.id !== id
  }));

  return (
    <div>
      {showNewExercise ? (
        <AddExercise
          suggestions={suggestions}
          workoutId={workoutId}
          exerciseIndex={exerciseIndex}
          suggestionValues={suggestionValues}
          exercise={newExercise}
          handleChange={handleAddExercise}
        />
      ) : (
        <UpdateExercise
          suggestions={suggestions}
          workoutId={workoutId}
          exerciseIndex={exerciseIndex}
          suggestionValues={suggestionValues}
          exercise={newExercise}
          handleChange={handleUpdateExercise}
        />
      )}
    </div>
  );
};

export default ExerciseAutocomplete;
