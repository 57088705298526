import React from 'react';
import max from 'lodash.max';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Loader from '../components/Loader';
import HistoryCard from '../components/HistoryCard';
import { LineChart } from 'react-chartkick';
import 'chartkick/highcharts';

const GET_EXERCISE = gql`
  query GetExercise($id: ID!) {
    exercise(id: $id) {
      id
      name
      workouts {
        id
        date
        sequences {
          id
          weight
          reps
        }
      }
    }
  }
`;

export default function ExerciseQuery({ id }) {
  const { loading, error, data } = useQuery(GET_EXERCISE, {
    fetchPolicy: 'network-only',
    variables: { id }
  });

  if (loading) return <Loader />;
  if (error) return <Redirect to="/404" />;

  const graphData = data.exercise.workouts.map(w => ({
    [new Date(w.date).toISOString().split('T')[0]]: max(w.sequences.map(s => parseInt(s.weight)))
  }));
  const listCards = data.exercise.workouts.map(workout => <HistoryCard key={workout.id} workout={workout} />);
  return (
    <div>
      <LineChart
        data={graphData.reduce((result, current) => {
          return Object.assign(result, current);
        }, {})}
      />
      <div>{data.exercise && listCards}</div>
    </div>
  );
}

ExerciseQuery.propTypes = {
  id: PropTypes.string.isRequired
};
